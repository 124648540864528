<template>

  <b-form-group
      :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
      :label="displayLabel?capitalize($t(name)):''"
      :key="componentKey"
  >
    <validation-provider
        v-if="isRequired"
        #default="{ errors }"
        :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
        rules="required"
    >
      <flat-pickr
          v-model="localModel"
          class="form-control w-100"
          :config="config"
          :placeholder="capitalize($tc(placeholder))"
          :disabled="disabled"
      />
      <small
          v-if="errors.length"
          class="text-danger"
      >{{ errors[0] }}
      </small>
    </validation-provider>


    <flat-pickr
        v-else
        v-model="localModel"
        :placeholder="capitalize($tc(placeholder))"
        class="form-control w-100"
        :config="config"
        :disabled="disabled"
        ref="flatPickr"
    />
  </b-form-group>

</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { required }                        from '@/utils/validations/validations'
import { capitalize }                      from '@/utils/filter'
import { French as FrenchLocale }          from 'flatpickr/dist/l10n/fr.js'
import { English as EnglishLocale }        from 'flatpickr/dist/l10n/default.js'
import { ValidationProvider }              from 'vee-validate'

import flatPickr from 'vue-flatpickr-component'
import i18n      from '@/libs/i18n'
import moment    from 'moment'
import { clone } from '../../utils/utils'

export default {
  components: {
    flatPickr,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    enableTime: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    onlyMonth: {
      type: Boolean,
      default: false
    },
    minDate: {},
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    // console.log(props.model)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const config = ref({
      locale: i18n.locale == 'fr' ? FrenchLocale : EnglishLocale,
      altInput: true,
      // altFormat: dateFormat.value,
      enableTime: props.enableTime,
      allowInput: true,
      // static: true,
      inline: props.inline,
    })
    const componentKey = ref(0)
    const flatPickr = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const dateFormat = computed(() => {
      if (i18n.locale == 'fr') {
        if (props.enableTime == true) {
          return 'd/m/Y H:i'
        } else if (props.onlyMonth == true) {
          return 'F Y'
        } else {
          return 'd/m/Y'
        }
      } else {
        if (props.enableTime == true) {
          return 'Y-m-d H:i'
        } else if (props.onlyMonth == true) {
          return 'F Y'
        } else {
          return 'Y-m-d'
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.model, val => {
      // console.log(val)
      localModel.value = val
    })

    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      required,
      capitalize,
      FrenchLocale,
      EnglishLocale,

      // Data
      localModel,
      config,
      componentKey,
      flatPickr,

      // Computed
      dateFormat,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    minDate: function (val) {
      console.log(val)
      if (moment(this.localModel) < moment(val)) {
        this.localModel = val
      }

      this.$set(this.config, 'minDate', val)
    },
    isRequired: function (val) {
      this.componentKey++
    },
    range: function (val) {
      // console.log(val)
      if (val == true) {
        this.$set(this.config, 'mode', 'range')
      }
    }
  },
  methods: {},
  mounted () {
    this.$set(this.config, 'altFormat', this.dateFormat)
    this.$set(this.config, 'minDate', this.minDate)
  },
  created () {
    if (this.range == true) {
      this.$set(this.config, 'mode', 'range')
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/vue/libs/vue-flatpicker';
</style>